import React from 'react';

import {Card, Button, Layout, Row, Col} from '@douyinfe/semi-ui';
import { Form } from '@douyinfe/semi-ui';

import axios from 'axios'
import AppHeader from "../components/header";
import {Outlet} from "react-router-dom";

export default function Auth() {
    const oauthBGMTV = () => {

    }

    const styleCenter = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }

    const { Header, Footer, Content, Sider } = Layout;

    return (
        <Layout className="components-layout-demo">
            <AppHeader />
            <Layout>
                <Content>
                    <div className={'grid'}>
                        <Row type={'flex'} justify={'center'}>
                            <Col span={20}>
                                <div style={styleCenter}>
                                    <Card>
                                        <h1>注册<span style={{ color: process.env.REACT_APP_COLOR }}> Nyamedia Emby Service</span></h1>
                                        <span>目前我们只接受从 @nyamedia_subscription_dev_bot 发起的注册请求。</span>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )

}